
<template>
  <div class="row gy-2">
    <div class="col-12">
      <h5 class="mb-3">List of collections</h5>
      <div class="row mb-3 gy-2 justify-content-between">
        <div class="col-lg-12 d-flex">
          <div class="dropdown no-arrow me-2">
            <a class="btn btn-light bg-white dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
              <i class="bx bx-menu"></i>
            </a>
            <ul class="dropdown-menu">
              <li @click.prevent="toggleFilters()">
                <button class="dropdown-item" type="button">
                  Toggle Filters
                </button>
              </li>
            </ul>
          </div>
          <b-button variant="primary" class="" @click.prevent="toggleFilters()"
          type="button"> <i class="ri ri-filter-2-line align-bottom"></i>    Filter </b-button>
        </div>
        <div class="col-md-6 col-lg-4">
          <div class="input-group w-auto">
            <b-form-select style="max-width:100px;" class="form-select" v-model="itemsPerPage" :options="pageOptions"></b-form-select>
            <select v-model="defaultStatus" class="form-select flex-grow-1">
              <option value="" disabled selected>-- sort by status --</option>
              <option :value="null" key="-2" > All status </option>
              <option v-for="(status, tIndex) in statusOptions"
                :value="status.value" :key="tIndex" > {{status.label}} </option>
            </select>
          </div>
        </div>
        <div class="col-md-5">
          <div class="input-group mb-0">
            <input v-model="searchQuery" @input="updateSearchQuery" type="search" class="form-control" placeholder="Search here..." aria-label="Search here..." aria-describedby="button-search_TB">
            <button @click="fetchItems()" class="btn btn-primary" type="button" id="button-search_TB"><span class="ri ri-search-line"></span></button>
          </div>
        </div>
      </div>
      <div class="mb-3">
        <p class="mb-0">Showing {{paginationInfo}} results</p>
      </div>
      <div class="card">
        <div class="card-body">
          <div class="table-responsive mb-0 loading-viewport">
            <is-loading v-if="isLoading" :box="true" />
            <b-table striped hover ref="selectableTable" :items="pageData.data"
                :fields="columns" responsive="sm"  align="middle">
                <template v-slot:cell(id)="data">
                  <div style="min-width:9rem;" @click="navigateTo(data.item.id)" class="cursor-pointer">
                    {{ data.item.friendly_id }}
                  </div>
                </template>
                <template v-slot:cell(title)="data">
                  <div style="min-width:9rem;" @click="navigateTo(data.item.id)" class="cursor-pointer">
                    {{ data.item.title }}
                  </div>
                </template>
                <template v-slot:cell(status)="data">
                  <div @click="navigateTo(data.item.id)"  class="cursor-pointer">
                    <span v-html="statusBadge(data.item.status)"></span>
                  </div>
                </template>
                <template v-slot:cell(started_at)="data">
                  <div style="min-width:6rem;width:6rem;" @click="navigateTo(data.item.id)"  class="cursor-pointer">
                    {{$filters.date(data.item.started_at, 1) }}
                  </div>
                </template>
                <template v-slot:cell(ended_at)="data">
                  <div style="min-width:6rem;width:6rem;" @click="navigateTo(data.item.id)"  class="cursor-pointer">
                    {{$filters.date(data.item.ended_at, 1) }}
                  </div>
                </template>
                <template v-slot:cell(invitations)="data">
                  <div @click="navigateTo(data.item.id)"  class="cursor-pointer">
                    <span class="text-dark font-sm">{{ data.item.invitations_count }}</span>
                  </div>
                </template>
                <template v-slot:cell(date)="data">
                  <div @click="navigateTo(data.item.id)" 
                  style="min-width:6rem;" class="cursor-pointer">
                    {{$filters.date(data.item.created_at, 1) }} 
                  </div>
                </template>
            </b-table>
          </div>
        </div>
      </div>
      <div class="mb-4">
        <b-pagination v-model="currentPage" :limit="4" class="pagination-rounded"
        :total-rows="pageData.total" :per-page="itemsPerPage"></b-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import IsLoading from '@/components/IsLoading.vue';
import _ from 'lodash';

export default {
  name: "collection-list",
  components:{
    IsLoading,
  },
  data() {
    return {
      isLoading: false,
      defaultStatus: null,
      searchQuery:"",
      statusOptions:[
        { value: "pending", label: "Pending" },
        { value: "ongoing", label: "Ongoing" },
        { value: "closed", label: "Closed" },
        { value: "canceled", label: "Canceled" }
      ],
      pageOptions: [10, 50, 100, 500, 1000],
      columns: [
        {
            key: "id",
            label: "ID",
        },
        {
            key: "title",
            label: "Title",
        },
        {
            key: "status",
            label: "Status",
        },
        {
            key: "started_at",
            label: "Start Date",
        },
        {
            key: "ended_at",
            label: "End Date",
        },
        {
            key: "invitations",
            label: "Applications",
        },
        {
            key: "date",
            label: "Created at",
        }
      ],
    }
  },
  watch: {
    defaultStatus (val) {
      this.fetchItems()
    }
  },
  computed: {
    pageData(){
      return this.$store.state.collection.pageData
    },
    currentPage: {
      get() {
        return this.pageData.current_page
      },
      set(val) {
        if(!this.pageData.current_page || this.pageData.current_page == val) return
        this.fetchItems(val)
      }
    },
    itemsPerPage: {
      get() {
        return +this.pageData.per_page || 10
      },
      set(val) {
        this.fetchItems(1, val)
      }
    },
    paginationInfo(){
      if(!this.pageData.total) return '0 - 0 of 0'
      return `${this.currentPage * this.itemsPerPage - (this.itemsPerPage - 1)} 
      - ${this.pageData.total - this.currentPage * this.itemsPerPage > 0 
      ? this.currentPage * this.itemsPerPage : this.pageData.total}
      of ${this.pageData.total}`
    },
  },
  methods: {
    navigateTo(id){
      this.$router.push({path: `/collections/${id}`});
    },
    updateSearchQuery: _.debounce(function(string) {
      this.fetchItems()
    }, 2000),
    fetchItems(page, per_page = null){
      let payload = {page: page || 1, per_page: per_page || this.itemsPerPage }
      if(this.defaultStatus){ payload.status = this.defaultStatus }
      if(this.searchQuery){ payload.search = this.searchQuery }
      this.isLoading = true
      this.$store.dispatch("collection/fetchItems", payload)
      .then(response => this.isLoading = false)
    },
    initializeAll(){
      this.popupModalShow = false;
    }
  },
  mounted() {
    this.fetchItems()
  },
}
</script>

